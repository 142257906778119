import styled from '@emotion/styled';
import {
  DataFieldProps,
  Form,
  SubmissionError,
  // useDispatch,
  useSelector,
} from '@innedit/formidable';
import { UserData } from '@innedit/innedit';
import { UtilisateurModel } from '@innedit/innedit-react';
import { navigate } from 'gatsby';
import React, { FC, useEffect } from 'react';
import { toast } from 'react-toastify';

import Button from '../../components/Button';
import { ReducersProps } from '../../reducers';
// import { load } from '../../actions/user';
import { borderRadius, spacing } from '../../styles/theme';
// import datas from './datas.json';

export const LoginEL = styled.div`
  background: #fff;
  padding: ${spacing[6]};
  margin: 0 auto;
  border-radius: ${borderRadius.s};

  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
`;

const Login: FC<{ href: string }> = ({ href }) => {
  // const dispatch = useDispatch();
  const {
    cms: { userIsLogged, user },
    form: { login },
  } = useSelector((globalState: ReducersProps) => globalState);

  useEffect(() => {
    try {
      UtilisateurModel.signInWithEmailLink(href);
    } catch (error: unknown) {
      toast.error((error as Error).message);
    }
  }, [href]);

  const handleOnClick = async () => {
    if (login && login.values && login.values.email) {
      try {
        await UserData.sendSignInLinkToEmail(login.values.email, href);
      } catch (error) {
        throw new SubmissionError({
          _error: (error as Error).message,
        });
      }
      toast.info('Un email a été envoyé avec un lien de connexion');
    } else {
      toast.error('Tu dois saisir ton adresse email');
    }
  };

  useEffect(() => {
    if (userIsLogged && user) {
      const espaceId = user.espace;

      if (espaceId) {
        navigate(`/espaces/${espaceId}/produits/`);
      } else {
        navigate(`/home/`);
      }
    }
  }, [userIsLogged, user?.id]);

  const handleFormSubmit = async ({
    code,
    email,
    password,
  }: {
    code?: string;
    email: string;
    password?: string;
  }): Promise<void> => {
    // let valid = false;
    // try {
    //   valid = await UserData.signInWithAuthCode(email, code);
    // } catch (error) {
    //   throw new SubmissionError({
    //     _error: (error as Error).message,
    //   });
    // }
    //
    // if (!valid) {
    //   throw new SubmissionError({
    //     _error: "Le code n'est pas bon",
    //   });
    // }

    if (!password) {
      throw new SubmissionError({
        _error: 'Le mot de passe est obligatoire',
      });
    }
    try {
      await UserData.signInWithEmailAndPassword(email, password);
      // if (result.user) {
      //   dispatch(load(result.user));
      // }
    } catch (error) {
      throw new SubmissionError({
        _error: (error as Error).message,
      });
    }
  };

  return (
    <LoginEL className="flex flex-col space-y-6 justify-center">
      <Form
        // actions={[
        //   {
        //     text: 'Mot de passe oublié ?',
        //     to: '/restauration/',
        //     variant: 'link',
        //   },
        // ]}
        datas={[
          {
            componentType: 'input',
            fieldProps: {
              autoComplete: 'email',
              inputMode: 'email',
            },
            label: 'Adresse email',
            name: 'email',
            required: true,
            type: 'email',
          } as DataFieldProps,
          {
            componentType: 'input',
            fieldProps: {
              autoComplete: 'current-password',
              inputMode: 'text',
            },
            label: 'Mot de passe',
            name: 'password',
            required: true,
            type: 'password',
          } as DataFieldProps,
          // {
          //   componentType: 'input',
          //   fieldProps: {
          //     autoComplete: 'one-time-code',
          //     inputMode: 'numeric',
          //     pattern: '[0-9]*',
          //   },
          //   label: 'Code authentification',
          //   name: 'code',
          //   required: true,
          //   type: 'text',
          // } as DataFieldProps,
        ]}
        footerProps={{
          className: 'mt-6 flex justify-between',
        }}
        name="login"
        onSubmit={handleFormSubmit}
        submitProps={{
          className: 'w-full',
          label: 'Se connecter',
        }}
      />

      <Button className="text-center" onClick={handleOnClick} variant="link">
        Se connecter sans mot de passe
      </Button>
    </LoginEL>
  );
};

export default Login;
