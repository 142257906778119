import React, { FC } from 'react';

import Layout from '../components/Layout';
import Espace from '../containers/Espace';

interface TemplatePublicProps {
  className?: string;
  espaceId?: string;
}

const TemplatePublic: FC<TemplatePublicProps> = ({
  className,
  espaceId,
  children,
}) => (
  <Layout kind="public" showNavigation={false}>
    {espaceId && <Espace id={espaceId} />}
    <div className={className}>{children}</div>
  </Layout>
);
export default TemplatePublic;
