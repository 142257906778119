import { PageProps } from 'gatsby';
import React, { FC } from 'react';

// import Button from '../components/Button';
import Login from '../containers/Login';
import TemplatePublic from '../templates/public';

const PageLogin: FC<PageProps> = ({ location }) => (
  <TemplatePublic className="md:p-24 flex flex-col justify-center items-center min-h-main">
    <div className="w-full max-w-md mx-auto">
      <h1 className="text-lg text-center mb-6">Se connecter</h1>

      <Login href={location.href} />

      {/* <p className="my-12 text-center">- ou -</p> */}
      {/* <Button */}
      {/*  className="w-full" */}
      {/*  color="primary" */}
      {/*  to="/inscription/" */}
      {/*  variant="outline" */}
      {/* > */}
      {/*  S&apos;inscrire */}
      {/* </Button> */}
    </div>
  </TemplatePublic>
);

export default PageLogin;
