import { EspaceData } from '@innedit/innedit';
import { DocumentType, EspaceType } from '@innedit/innedit-type';
import React, { FC, useEffect, useState } from 'react';

import Button from '../components/Button';

const Espace: FC<{ id: string }> = ({ id }) => {
  const [espace, setEspace] = useState<DocumentType<EspaceType>>();

  useEffect(() => {
    let isMounted = true;
    const espaceData = new EspaceData();
    const unsub = espaceData.watchById(id, snapshot => {
      if (isMounted) {
        setEspace(snapshot);
      }
    });

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [id]);

  if (!espace) {
    return null;
  }

  return (
    <div className="flex space-x-3 bg-light-400 p-6 rounded my-12">
      <span>{espace.name}</span>

      <Button to={`/${id}/`} variant="link">
        Voir cet espace
      </Button>
    </div>
  );
};

export default Espace;
